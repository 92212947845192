import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

import 'boxicons';

import BgSurabaya from '../../assets/background-surabaya-webapp.jpg';
import fotoAnnisa from '../../assets/foto-annisa.jpg';
import fotoKojo from '../../assets/foto-kojo.jpg';

const DivHero = styled.div`
  height: 110vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center bottom;
  background-size: cover;

  h1 {
    font-size: 4em;
    text-align: center;
  }

  h2 {
    font-size: 2rem;
    text-align: center;
  }

  .lokasi {
    font-size: 2em;
    text-align: center;
    background: #000;
    color: #fff;
    padding: 0px 20px 3px 20px;
    border-radius: 50px;
    margin-top: 15px;
  }

  .lokasi box-icon {
    margin-right: 10px;
  }
`;

const DivContact = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;

  a {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 50px;
    font-size: 1.2em;
    color: #fff;
    text-align: center;
    width: 40vw;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0.5px 10px;
  }

  box-icon {
    margin-right: 5px;
  }
  
  .whatsapp {
    background-color: #47A44B;
  }
  .whatsapp:hover {
    background-color: #307733;
  }

  .telp {
    background-color: #00AEC5;
  }
  .telp:hover {
    background-color: #017989;
  }

  @media (min-width: 768px) {
    a {
      max-width: 300px;
    }
  }
`;

const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.1em;
    font-weight: 200;

    margin-bottom: 15px;
  }
`;

const ListWhatsapp = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(71, 164, 75);
  color: #fff;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  gap: 15px;

  div:first-child {
    width: 100px;
    height: 100px;
    border-radius: 20px;

    background-position: center bottom;
    background-size: cover;
  }

  ul {
    list-style: none;
  }
  
  li {
    font-size: 1.2em;
    font-weight: 700;
  }
`;

const ListTelp = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(0, 174, 197);
  color: #fff;
  padding: 10px;
  border-radius: 20px;
  margin-bottom: 10px;
  gap: 15px;

  div:first-child {
    width: 100px;
    height: 100px;
    border-radius: 20px;

    background-position: center bottom;
    background-size: cover;
  }

  ul {
    list-style: none;
  }
  
  li {
    font-size: 1.2em;
    font-weight: 700;
  }
`;

Modal.setAppElement('#root');

const Hero = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [isOtherOpen, setIsOtherOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }
  function otherToggleModal() {
    setIsOtherOpen(!isOtherOpen);
  }

  return (
    <DivHero style={{
      backgroundImage: `url(
        ${BgSurabaya}
        )`
      }}>
      <h2 className='lokasi'><box-icon name='map' color='#fff'></box-icon>Surabaya</h2>
      <h1>Tiga Dara</h1>
      <h2>Catering & Wedding Organizer</h2>
      <DivContact>
        <a className='whatsapp' onClick={toggleModal}><box-icon name='whatsapp' type='logo' color='#fff'></box-icon>Whatsapp</a>
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModal}
          contentLabel="My dialog"
          style={{
            overlay: {
              backgroundColor: 'rgba(129, 92, 92, 0.75)',
              zIndex: '8',
            },
            content: {
              top: '150px',
              borderRadius: '20px',
            }
          }}
        >
          <ContainerModal>
            <h3>Silahkan Pilih Marketing Kami:</h3>
            <ListWhatsapp href="https://api.whatsapp.com/send?phone=62081517085755&amp;text=Silahkan%20tinggalkan%20pesan%20anda%20.....">
                <div style={{backgroundImage: `url(${fotoAnnisa})`}}></div>
                <div>
                  <ul>
                    <li>Annisa</li>
                    <li>081517085755</li>
                    <li>Indosat</li>
                  </ul>
                </div>
            </ListWhatsapp>
            <ListWhatsapp href="https://api.whatsapp.com/send?phone=62081519329305&amp;text=Silahkan%20tinggalkan%20pesan%20anda%20.....">
                <div style={{backgroundImage: `url(${fotoKojo})`}}></div>
                <div>
                  <ul>
                    <li>Yohannes</li>
                    <li>081519329305</li>
                    <li>Indosat</li>
                  </ul>
                </div>
            </ListWhatsapp>
            {/* <button onClick={toggleModal}>Tutup Dialog</button> */}
          </ContainerModal>
        </Modal>

        <a className='telp' onClick={otherToggleModal}><box-icon name='phone-call' type='solid' color='#fff'></box-icon>No.Telepon</a>
        <Modal
          isOpen={isOtherOpen}
          onRequestClose={otherToggleModal}
          contentLabel="My dialog"
          style={{
            overlay: {
              backgroundColor: 'rgba(129, 92, 92, 0.75)',
              zIndex: '8',
            },
            content: {
              top: '150px',
              borderRadius: '20px',
            }
          }}
        >
          <ContainerModal>
            <h3>Silahkan Pilih Marketing Kami:</h3>
            <ListTelp href="tel:081517085755">
                <div style={{backgroundImage: `url(${fotoAnnisa})`}}></div>
                <div>
                  <ul>
                    <li>Annisa</li>
                    <li>081517085755</li>
                    <li>Indosat</li>
                  </ul>
                </div>
            </ListTelp>
            <ListTelp href="tel:081519329305">
                <div style={{backgroundImage: `url(${fotoKojo})`}}></div>
                <div>
                  <ul>
                    <li>Yohannes</li>
                    <li>081519329305</li>
                    <li>Indosat</li>
                  </ul>
                </div>
            </ListTelp>
            {/* <button onClick={otherToggleModal}>Tutup Dialog</button> */}
          </ContainerModal>
        </Modal>

      </DivContact>
    </DivHero>
  )
}

export default Hero