import React from 'react';
import styled from 'styled-components';

import DataArticles from './DataArticles';
import Article from './Article'

const DivArticles = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  width: 100%;
  // background-color: #2f1d58;
  // height: 80vh;
  overflow: auto;
  flex-direction: row;

  div {
    width: 44vw;
    // height: 100px;
    margin: 10px;
    // background-color: lightblue;
  }

  div:last-child {
    width: 100vw;
  }

  h1 {
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  p {
    text-align: justify;
    font-size: 0.9em;
    line-height: 1.7em;
  }

  hr {
    width: 100%;
    border: 1px solid #ebebeb;
    margin: 30px 0;
  }

  @media (max-width: 425px){
    h1 {
      font-size: 0.8em;
    }

    p {
      font-size: 0.7em;
    }
  }
`;

const Articles = () => {
  return (
    <>
    <DivArticles>
    <hr />
      {DataArticles.map((item, index) => (
              <Article {...item} key={item.title + index} />
          ))}
    </DivArticles>
    </>
  )
}

export default Articles