import React from 'react';

import Navbar from '../Navbar/Navbar';
import Hero from '../Hero/Hero';
import InfoHeader from '../InfoHeader/InfoHeader';

const Header = () => {
  return (
    <>
    <Navbar />
    <Hero />
    <InfoHeader />
    </>
  )
}

export default Header