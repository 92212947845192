import React, { useState } from 'react';
import styled from 'styled-components';
import './Navbar.css';
import Logo from '../../assets/logo-tdc-export.png';
import RightNav from './RightNav';

const Nav = styled.nav`
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: 9;
  border-top: 5px solid #EE3B3A;
  background: #f4f4f4;
  height: 4em;
  padding: 0 3vw;
  position: fixed;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 12px;
`

const LogoNav = styled.div`
  flex: 0.5;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: -5px;

  img {
    width: 100%;
    max-width: 100px;
  }
`
const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 20;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    margin-top: 3px;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => open ? '#ccc' : '#333'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    background-color: #ee3b3a;

    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }

    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }

    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <Nav>
      <LogoNav>
        <img src={Logo} alt="Tiga Dara Catering & Wedding Organizer" />
      </LogoNav>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open}/>
    </Nav>
  )
}

export default Navbar