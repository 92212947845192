import React from 'react';
import styled from 'styled-components';

import LinkMenuSmall from './LinkMenuSmall';
import CardMenuSmall from './CardMenuSmall';

const DivMenuSmall = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem 5vw;
    
    @media (max-width: 425px) {
      padding: 2rem 0.5rem;
    }
    
    h1 {
      font-size: 1.5em;
      margin-bottom: 1rem;
    }

    ul {
      list-style: none;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      width: 100%;
      gap: 10px;
    }
    
    li {
      display: flex;
      flex-direction: column;

      border: 1px solid #e0e0e0;
      border-radius: 20px;
      box-shadow: 0 0 12px rgba(0,0,0,0.1);
    }
    
    @media (max-width: 425px) {
      ul {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      }
    }
`;

const MenuSmall = () => {
  return (
    <DivMenuSmall>
      <h1>MENU UTAMA</h1>
      <ul>
        {LinkMenuSmall.map((item, index) => (
            <CardMenuSmall {...item} key={item.title + index} />
        ))}
      </ul>
    </DivMenuSmall>
  )
}

export default MenuSmall