import React from 'react';
import styled from 'styled-components';

const DivImage = styled.div`
    background-repeat: no-repeat;
    background-size: cover;
    height: 280px;
    width: 100%;
    background-position: center center;
    background-blend-mode: overlay;
    border-radius: 20px 20px 0 0;
    -webkit-box-shadow: inset 0px -14px 22px -11px rgba(0,0,0,0.43);
    -moz-box-shadow: inset 0px -14px 22px -11px rgba(0,0,0,0.43);
    box-shadow: inset 0px -14px 22px -11px rgba(0,0,0,0.43);

    @media (max-width: 425px) {
      height: 130px;
    }
`;

const DivTitle = styled.div`
    padding: 15px 10px 20px 10px;
    font-weight: 500;
    font-size: 1.1em;
    text-align: center;
    
`;

const CardMenuSmall = ({title, image, link}) => {
  return (
    <a href={link} rel="noopener noreferrer">
        <li>
        <DivImage style={{backgroundImage: `url(${image})`}}></DivImage>
        <DivTitle>{title}</DivTitle>
        </li>
    </a>
  )
}

export default CardMenuSmall