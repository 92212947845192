import React from 'react';

const Article = ({title, seo}) => {
  return (
    <div>
        <h1>{title}</h1>
        <p>{seo}</p>
    </div>
  )
}

export default Article