import React from 'react';

const MenuNav = ({title, link}) => {
  return (
    <>
        <a href={link} rel="noopener noreferrer"><li>{title}</li></a>
    </>
  )
}

export default MenuNav