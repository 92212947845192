const LinkMenu = [
    {
        title: "Paket Pernikahan",
        link: "https://surabaya.tigadaracatering.id/paket-pernikahan-surabaya"
    },
    {
        title: "Paket Rumahan",
        link: "#"
    },
    {
        title: "Paket Bundling 200",
        link: "https://surabaya.tigadaracatering.id/paket-bundling-intimate-surabaya"
    },
    {
        title: "Paket Bundling 500",
        link: "https://surabaya.tigadaracatering.id/price-list-bundling-gedung-surabaya"
    },
    {
        title: "Gubukan",
        link: "https://surabaya.tigadaracatering.id/gubukan-murah-surabaya"
    },
]

export default LinkMenu