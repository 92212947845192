import React from 'react';
import styled from 'styled-components';
import MenuNav from './MenuNav';
import LinkMenu from '../../util/LinkMenu'

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  flex: 1;
  gap: 20px;

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 10rem;
    padding-left: 2rem;
    transition: transform 0.3s ease-in-out;
    gap: 50px;
    justify-content: flex-start;
    z-index: 9;

    a {
      text-decoration: none;
      color: initial;
    }

    li {
      color: #fff;
      list-style: none;
      margin-right: 20px;
    }
  }
`;

const RightNav = ({open}) => {
  return (
    <Ul open={open}>
      {LinkMenu.map((item, index) => (
                  <MenuNav {...item} key={item.title + index} />
                ))}
    </Ul>
  )
}

export default RightNav