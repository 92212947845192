import React from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";

// Image
import BannerPromo1 from '../../assets/banner-promo-1.jpg';
import BannerPromo2 from '../../assets/banner-promo-2.jpg';
import BannerPromo3 from '../../assets/banner-promo-3.jpg';
import BannerPromo4 from '../../assets/banner-promo-4.jpg';

const BannerSlider = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 1
          }
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        allowTouchMove={true}
        centeredSlides={true}
        spaceBetween={1}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
      >
        <SwiperSlide style={{
          backgroundImage: `url(
            ${BannerPromo1}
            )`
          }} />
        <SwiperSlide style={{
          backgroundImage: `url(
            ${BannerPromo2}
            )`
          }} />
        <SwiperSlide style={{
          backgroundImage: `url(
            ${BannerPromo3}
            )`
          }} />
        <SwiperSlide style={{
          backgroundImage: `url(
            ${BannerPromo4}
            )`
          }} />
        {/* <SwiperSlide style={{
          backgroundImage: `url(
            ${Team2}
            )`
          }} /> */}
      </Swiper>
    </>
  )
}

export default BannerSlider