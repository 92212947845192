import React from 'react';

import MenuSmall from '../../component/MenuSmall/MenuSmall.js'


const Menu = () => {
  return (
    <>
    <MenuSmall />
    
    </>
  )
}

export default Menu