import React from 'react';
import styled from 'styled-components';
import 'boxicons';

import BannerSlider from '../../component/BannerSlider/BannerSlider';

import BannerXYZ from '../../assets/tigadara-team-1.jpeg';
import BannerABC from '../../assets/tigadara-team-2.jpeg';
import BannerBCA from '../../assets/banner-bca.jpeg';

const DivContainer = styled.div`
    background-color: rgb(220 220 220);
    padding: 1vh 5vw;
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 2vw;
    align-items: center;

    @media screen and (max-width: 425px) {
        flex-direction: column;
        padding: 10px;
        gap: 5vw;
    }

    img {
        width: 100%;
        border-radius: 20px;
    }

`
const InfoStore = styled.div`
    flex: 0.3;
    display: flex;
    flex-direction: row;
    padding: 2vh 0;
    color: #000;
    gap: 10px;
    margin-top: -5rem;

    background: rgb(255,185,185);
    background: linear-gradient(-120deg, rgba(255,185,185,1) 0%, rgba(255,131,120,1) 100%);
    border-radius: 50px;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0.5px 10px;

    align-items: center;

    @media screen and (max-width: 425px) {
        flex: 1;
    }
`

const OfficeHours = styled.div`
    display: flex;
    flex: 0.5;
    flex-direction: column;

    div {
        background: #000;
        color: #fff;
        padding: 7px;
        font-weight: 900;
        font-size: 1.2em;
        letter-spacing: 1px;
        margin-bottom: 10px;
    }

    h3 {
        font-weight: 900;
        font-size: 1.6em;
        margin-bottom: 10px;
        line-height: 1;
    }

    p {
        letter-spacing: 1px;
        font-size: 0.8em;
        font-weight: 500;
        margin-top: 5px;
    }
`

const OfficeDays = styled.div`
    display: flex;
    flex: 0.5;
    flex-direction: column;
    align-items: center;

    h3 {
        font-size: 1.5em;
        margin-bottom: -15px;
    }

    h2 {
        font-size: 3em;
        font-weight: 900;
    }
`

const StyledSwiper = styled.div`
    flex: 0.7;
    display: flex;
    flex-direction: row;
    padding: 2vh 0;
    width: 100%;
    margin-top: -5rem;

    
  .swiper {
    width: 100%;
  }

  .swiper-wrapper {
    max-width: 60vw;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    border-radius: 50px;
    // height: 40vh;
    height: 650px;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;


    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .swiper-pagination-bullet {
    background: #ffb0b2;
    opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.5);
  }

  .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #ec1d23;
  }

  @media (max-width: 425px) {
    margin: -1rem 0;

    .swiper-slide {
        height: 210px;
        border-radius: 20px;
    }
}

`
const DivBannerLg = styled.div`
    flex: 1;
    display: flex;
    height: auto;
  `
  const DivBannerSm = styled.div`
    flex: 0.5;
    display: flex;
    height: auto;

    @media (max-width: 425px) {
        padding-bottom: 20px;
    }
`




const InfoHeader = () => {
  return (
  <>
    <DivContainer>
        <InfoStore>
            <OfficeHours>
                <div>Office Hours</div>
                <h3>Selasa<br />s.d Minggu</h3>
                <p>KUNJUNGAN KE KANTOR DILUAR JAM KERJA BISA DENGAN JANJI TERLEBIH DAHULU</p>
            </OfficeHours>
            <OfficeDays>
                <h3>Buka</h3>
                <h2>09.00</h2>
                <h3>Tutup</h3>
                <h2>16.00</h2>
            </OfficeDays>
            {/* <h3>Office Hour</h3>
            <p>
            <box-icon type='solid' 
            name='calendar'
            size='1.2em'
            color='#000'></box-icon>
            Selasa - Minggu</p>
            <p>
            <box-icon type='solid' 
            name='time-five'
            size='1.2em'
            color='#000'></box-icon>
            ( 09.00 - 16.00 )</p>
        <p>KUNJUNGAN KE KANTOR DILUAR JAM KERJA BISA DENGAN JANJI TERLEBIH DAHULU</p> */}
        </InfoStore>
        <StyledSwiper>
            <BannerSlider />
        </StyledSwiper>
    </DivContainer>
    <DivContainer>
        <img src={BannerBCA} alt='Paket Pernikahan Surabaya' />
    </DivContainer>
    <DivContainer style={{paddingBottom:`20px`}}>
        <DivBannerLg>
            <img src={BannerABC} alt='Wedding Package Surabaya' />
        </DivBannerLg>
        <DivBannerSm>
            <img src={BannerXYZ} alt='Tiga Dara Catering Surabaya' />
        </DivBannerSm>
    </DivContainer>
  </>
  )
}

export default InfoHeader